let origin = "https://future.zhuneng.cn";

//获取商品详情
const getGoodsDetailUrl = `/gateway/hc-serve/miniapi/pointsMall/goodsDetailById`;

//订单详情页
const getOrderDetailUrl = `/gateway/hc-serve/miniapi/pointsMall/userPointExchangeDetail`;
//积分订单列表
const getOrderListUrl = `/gateway/hc-serve/miniapi/pointsMall/pointExchangeListByShop`;
//核销
const handleOrderUrl = `/gateway/hc-serve/miniapi/pointsMall/sceneOrderCompletedByShop`;
//取消兑换
const cancelOrderUrl = `/gateway/hc-serve/manageapi/pointsMallOrder/cancelOrder`;
export {
  cancelOrderUrl,
  getGoodsDetailUrl,
  getOrderDetailUrl,
  getOrderListUrl,
  handleOrderUrl,
};
